/* Header Component */

.com-header-container {
  background-color: #fff !important;
}

.com-header-container .header {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  /* color: #000 !important; */
  margin: 0;
  padding: 0.3em;
}

.com-header-container .image {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.com-header-container .language {
  margin-top: 6px;
  float: right;
}

.com-header-container .initial {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  margin-top: 3px !important;
  margin-left: 5px !important;
  border: 1px solid #000 !important;
  float: right;
}

/* Footer Component */

.com-footer-container {
  background-color: #dbdbdb !important;
  padding: 20px 20px !important;
  margin: 0 !important;
}

.com-footer-container .logo {
  fill: #000 !important;
  height: 35px !important;
  margin: 3px !important;
}

.com-footer-container label {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
}

.com-footer-container .actionlink {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  text-align: right !important;
}

.com-footer-container .actionlink .item {
  color: #000 !important;
}

/* ContactForm Component */

.com-contact-container {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  text-align: justify;
}

.com-contact-container .contactus {
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  text-align: left;
}

.com-contact-container .info {
  font-size: 11px !important;
  font-style: normal !important;
  color: #00518a !important;
  text-align: left;
}

/* Sidebar Component */

.com-sidebar-container {
  margin-top: -14px;
}

.com-sidebar-divider {
  margin-top: 65vh !important;
}

.com-sidebar-container .header {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #939598 !important;
  margin: 30px 0px 30px 0px;
}

.com-sidebar-container .button {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  /* font-size: 14px !important; */
  font-style: normal !important;
  background-color: #00518a;
  color: #ffffff;
}

.com-sidebar-container .button:hover {
  background-color: #00518a;
  color: #a8aaac;
}

.com-sidebar-container .active-item {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-style: normal !important;
  font-size: 16px !important;
  padding: 12px 10px 12px 10px !important;
  border-right: 5px solid #00518a !important;
}

.com-sidebar-container .inactive-item {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-style: normal !important;
  font-size: 16px !important;
  padding: 12px 10px 12px 10px !important;
  border-right: 1px solid #ffffff !important;
}

.com-sidebar-container .with-notif {
  background-color: #4385d0 !important;
  color: #ffffff !important;
  padding: 4px 5px !important;
  margin-left: 10px !important;
}

.com-sidebar-container .no-notif {
  display: none !important;
}

.com-sidebar-container .item-icon {
  margin-right: 10% !important;
  float: left !important;
}

.com-sidebar-container .placeholder {
  margin-bottom: 20vh !important;
}

.com-sidebar-container .pusher {
  height: 100vh !important;
  overflow: scroll !important;
}

/* Container */
div.container-wrapper {
  width: 80%;
  padding: 20px;
}

.custom-label {
  display: block;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
}

.error-page-contact-container {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  text-align: center;
}

.error-page-contact-container .contactus {
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  text-align: center;
}

.combination-label-quantity-input {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.ui-alerts {
  position: fixed !important;
  top: 1em !important;
  right: 1em !important;
  z-index: 9999 !important;
}

.inline-flex {
  display: inline-flex;
}

.center {
  text-align: center;
}

.custom-ol {
  list-style-type: none;
}

.ready-schedule:before,
.chk-rad-error:before {
  left: 20% !important;
}

.field.error .radio label,
.field.error .checkbox label,
.field.error.rad-chk-field {
  color: #9f3a38;
}
.field.error .radio label:before,
.field.error .checkbox label:before {
  border-color: #e0b4b4;
}
.field.error.rad-chk-field {
  font-weight: 700;
  font-size: 0.92857143em;
}

.custom-previous-time {
  color: red;
}

table.table-bordered {
  border: 1px solid #a0a2a4;
}

table.table-bordered tr td {
  border: 1px solid #a0a2a4;
  padding: 2px;
}

table.table-child tr td,
table.table-child tr th {
  border: 1px solid #a0a2a4;
}

table.no-border tr td {
  border: 1px solid #ffffff;
  padding: 2px;
}

.margin-left {
  margin-left: 25px;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.eg-note {
  font-size: smaller;
  font-style: italic;
}

.margin-right-5 {
  margin-right: 5px;
}

.manual-pricing {
  background-color: #f49203 !important;
  border-radius: 0.28571429rem 0.28571429rem 0 0 !important;
}

.cookie-about,
.cookie-about:hover {
  color: #fff;
  text-decoration: underline;
}

.help-me-msg,
.msg {
  word-break: break-word;
}

.help-me-msg:after {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #ccc;
  position: absolute;
  top: 0;
  right: 10px;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 0ms linear;
}

.location-selection .description {
  color: #474d54;
}

.location-selection .meta {
  color: #a4a5a8;
}

.location-selection .arrow {
  color: #a4a5a8;
  margin-left: 20px;
}

.myDiv {
  position: relative;
  z-index: 1;
  height: 705px;
  width: 1502px;
  margin: 50px 100px;
  border: 1px solid #d8d8d8;
}

.myDiv .bg {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../img/hand-laptop.jpg") center center;
  opacity: 0.5;
  height: 700px;
  width: 1500px;
  background-size: cover;
}

.myDiv .layer {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  height: 700px;
  width: 1500px;
}

.welcome-header {
  padding: 200px 100px 1px 100px;
  text-align: center;
}

.label-applications {
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.label-applications-header {
  padding-top: 50px;
  text-align: center;
}

.label-applications div {
  width: 90%;
  margin: auto;
  padding-bottom: 50px;
}

.main-div {
  width: 70%;
  padding: 20px;
}

.reported-usage-div {
  width: 70%;
  padding: 20px 20px 20px 40px;
}

.srpv-sidebar-color {
  background-color: ghostwhite;
}

.srpv-modal-header-bg {
  background-color: black !important;
  color: white !important;
}

.active-file-info {
  background-color: #ababab !important;
}

#root {
  background-color: rgb(237, 241, 246) !important;
}
