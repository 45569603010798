.tableHeader{
    text-align: center;
    background: #CDD6E4 !important;
    opacity: 1;
    border-left: 1px  ridge #BBC1C8 !important;
    border-right: 1px  ridge #BBC1C8 !important;
}



.table > tr:nth-child(even) {background: #EDF1F6 }
.table > tr:nth-child(odd) {background: #FFFFFF }
.table > tr > td {
    border-left: 1px  ridge #BBC1C8 !important;
    border-right: 1px  ridge #BBC1C8 !important;
   
}

.actionLabel{
    display: flex;
    flex:1;
    font-size: 1em;
}

.actionLabel b{
    color:Red;
}

.modalContent{
    padding:0 !important;
    background-color: #E4EBF5 !important;
}

.modalContentTab > div > a{
    font-size: 1em;

 
}
.label{
    background-color: transparent !important;
    color:black !important;
    display: grid !important;
}

.labelLight{
    background-color: transparent !important;
    color:#9EA6BA !important;
}

.errorBar{
    height: 5vh;
    background-color: #E54C50;
    display: flex;

    flex:1;
    flex-direction: row;
    align-items: center;
    padding-left:10px;

}

.errorBar > i{
    color:white;
    font-size: 1.5em;
}


.errorBar > label{
    color:white;
    font-size: 1em;
}
.successBar{
    height: 5vh;
    background-color:#3D9B35;
    display: flex;

    flex:1;
    flex-direction: row;
    align-items: center;
    padding-left:10px;

}

.successBar > i{
    color:white;
    font-size: 1.5em;
}


.successBar > label{
    color:white;
    font-size: 1em;
}

.mainContainer{
    width: 100vw !important;
    padding:10px;
}
.whiteBoxContainer{
    max-width: 100%;
}
